import React from 'react';
import Logo from '../../components/Logo/Logo'
import Shoutbox from '../../components/Shoutbox/Shoutbox'

const Home = ({token, onNickChange, nick}) => (
  <div>
    <Logo token={token} />
    <Shoutbox shoutbox_id={"radio"} token={token} onNickChange={onNickChange} nick={nick} />
  </div>
);

export default Home;
