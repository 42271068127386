import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import Track from '../Track/Track';
import './Release.css';
import 'w3-css';

const Release = ({ data, tracks, token, selected, onReleaseChange, onTrackChange, currentTrack, selectedTrack, releaseOpen, slideDir }) => {
  const [isSelected, setIsSelected] = useState(selected);
  const [metadata, setMetadata] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const coverThumbPath = `../cover_thumbnails/${data.id}/250.png`;
  const coverMidThumbPath = `../cover_thumbnails/${data.id}/500.png`;
  const coverPath = `../releases/${data.directory_path}/cover.png`;
  const [showBigCoverArt, setShowBigCoverArt] = useState(false);
  const navigate = useNavigate();
  const ogTitle = `${data.album}`
  const ogDescription = `Listen to ${data.album} by ${data.artist} on MIETAA`

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if(eventData.dir === 'Right'){
        setShowBigCoverArt(false);
      }
      if(eventData.dir === 'Left'){
        setShowBigCoverArt(false);
      }
    },...{},
  });

  const handleReleaseOpen = (event) => {
    setShowBigCoverArt(false);
    onReleaseChange(data.id, 'open');
  };

  const handleReleaseClose = (event) => {
    setShowBigCoverArt(false);
    event.stopPropagation();
    onReleaseChange(null, 'close');
    navigate(`/catalog`);
  };

  const handlePrevRelease = useCallback((event) => {
    setShowBigCoverArt(false);
    event.stopPropagation();
    onReleaseChange(data.id, 'prev');
  }, [onReleaseChange, data]);

  const handleNextRelease = useCallback((event) => {
    setShowBigCoverArt(false);
    event.stopPropagation();
    onReleaseChange(data.id, 'next');
  }, [onReleaseChange, data]);

  const handleCoverClick = () => {
    setShowBigCoverArt(!showBigCoverArt);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowLeft':
            setShowBigCoverArt(false);
          break;
        case 'ArrowRight':
            setShowBigCoverArt(false);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showBigCoverArt]);

  const handleTrackChange = (track) => {
    onTrackChange(track, null);
    onReleaseChange(data.id, null);
  }

  return (
    <div className={"release"} {...handlers}>
      {selected && (
        <Helmet key={data.id}>
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:image" content={coverMidThumbPath} />
        </Helmet>
      )}
      {!releaseOpen && (
        <div className={"menuEntry"} onClick={handleReleaseOpen}>
          <img className="coverThumb" src={coverThumbPath} alt={`${data.artist} - ${data.album} Cover`} />
          <span className="metadata">{`${data.artist} - ${data.album}`}</span>
        </div>
      )}

      {selected && (
        <div>
          <div className="headingContainer">
            <h2>{data.artist + " - " + data.album}</h2>
            <div className="controls">
              <button className="prevRelease" onClick={handlePrevRelease}><FaArrowLeft /></button>
              <button className="nextRelease" onClick={handleNextRelease}><FaArrowRight /></button>
            </div>
            <button className="closeOverlay" onClick={handleReleaseClose}><FaTimes /></button>
          </div>
          <div className={"albumOverlay"}>

            <div className="albumContainer">
              <div className={slideDir}>

              <img className={"coverMid"} onClick={handleCoverClick} src={coverMidThumbPath} alt={`${data.artist} - ${data.album} Cover`} />


              {showBigCoverArt &&
                <div className="coverOverlay">
                  <img className={"coverBig"} onClick={handleCoverClick} src={coverPath} alt={`${data.artist} - ${data.album} Cover`} />
                </div>
              }

              {tracks.map((track, index) => (
                <Track
                  key={track.file_path}
                  data={track}
                  onTrackChange={handleTrackChange}
                  directory_path={data.directory_path}
                  isSelected={track.id === selectedTrack}
                  isHighlighted={track.id === currentTrack}
                />
              ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Release;
