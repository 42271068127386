import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaPause } from "react-icons/fa";
import './MusicPlayer.css';

const MusicPlayer = ({ token, track, release, radioOn, radioOff, isRadioPlaying, onTrackChange }) => {
  const audio = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [streamMetadata, setStreamMetadata] = useState('');
  const [audioSrc, setAudioSrc] = useState(process.env.REACT_APP_RADIO_MP3_PATH);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  const fetchMetadata = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_RADIO_STATS_PATH}`
      );
      if (response.ok) {
        const data = await response.json();
        const songTitle = data.icestats.source.title;
        if (songTitle ) {
          setStreamMetadata(songTitle);
        } else {
          setStreamMetadata(data.icestats.source[0].title);
        }
      }
    } catch (error) {
      console.error('error fetching metadata:', error);
    }
  };

  const playNextTrack = () => {
    if (currentTrackIndex < release.tracks.length - 1) {
      const nextTrackIndex = currentTrackIndex + 1;
      setCurrentTrackIndex(nextTrackIndex);
      const nextTrack = release.tracks[nextTrackIndex];
      const newAudioSrc = `https://mietaa.fi/releases/${release.directory_path}/${nextTrack.file_path}`;
      
      if (newAudioSrc) {
        setAudioSrc(newAudioSrc);
        audio.current.src = newAudioSrc;
        audio.current.load();
        onTrackChange(release.tracks[nextTrackIndex])
        audio.current.play().catch((error) => {
          console.error('Playback failed:', error);
        });
      } else {
        console.error('Next track not found.');
      }
    } else {
      handlePause();
    }
  };
  
  useEffect(() => {
    if (track && track.file_path && release && release.directory_path) {
      const newAudioSrc = `https://mietaa.fi/releases/${release.directory_path}/${track.file_path}`;
      if (newAudioSrc) {
        setAudioSrc(newAudioSrc);
        audio.current.src = newAudioSrc;
        audio.current.load();
        radioOff();
        setIsPlaying(true);
        setCurrentTrackIndex(release.tracks.findIndex(t => t.id === track.id));
      } else {
        console.error('Failed to load track.');
      }
    }
  }, [track]);
  
  useEffect(() => {
    if (audioSrc && audio.current.src && audio.current.paused) {
      setIsPlaying(true);
      audio.current.play().catch((error) => {
        console.error('Playback failed:', error);
      });
    }
  }, [audioSrc]);

  useEffect(() => {
    if(isRadioPlaying){
      setAudioSrc(process.env.REACT_APP_RADIO_MP3_PATH);
      audio.current.src = process.env.REACT_APP_RADIO_MP3_PATH;
      audio.current.load();
      setIsPlaying(true);
      radioOn();
      handlePlay();
    } else {
      fetchMetadata();
      handlePause();
      radioOff();
    }
  }, [isRadioPlaying]);

  useEffect(() => {
    fetchMetadata();
    if(isRadioPlaying){
      const intervalId = setInterval(() => {
        fetchMetadata();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isRadioPlaying]);

  const handlePlay = async () => {
    if(audio.current && audio.current.paused) {
      try {
        if(audioSrc === process.env.REACT_APP_RADIO_MP3_PATH && !isRadioPlaying){
          radioOn();
        }
        await audio.current.play();
        setIsPlaying(true)
      } catch (error) {
        console.error('Playback failed:', error);
      }
    }
  };

  const handlePause = () => {
    if(audioSrc === process.env.REACT_APP_RADIO_MP3_PATH){
      radioOff();
    }
    setIsPlaying(false);
    audio.current.pause();
  }

  const trimTitle = (title) => {
    const length = 60;
    const trimmedString = title.length > length ? title.substring(0, length - 3) + "..." : title;
    return trimmedString;
  }

  return (
    <div className="musicPlayer">
      <div className="playerContent">
        <audio ref={audio} onEnded={playNextTrack} hidden>
          <source src={audioSrc ? audioSrc : `${process.env.REACT_APP_RADIO_MP3_PATH}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {track || streamMetadata ? (
          <>
            {isPlaying ? (
              <button className="pause" onClick={handlePause}>
                <FaPause />
              </button>
            ) : (
              <button className="play" onClick={handlePlay}>
                <FaPlay />
              </button>
            )}
            <div className="metadata">
              <span className="metadata">{track && !isRadioPlaying ? `${track.artist} - ${trimTitle(track.title)}` : `${trimTitle(streamMetadata)}`}</span>
            </div>
          </>
        ) : (
          <div className="metadata">
            <span className="metadata">{`loading stream metadata`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPlayer;
