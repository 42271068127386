import React from 'react';
import { FaPlay } from "react-icons/fa";
import './Track.css';

const Track = ({ data, onTrackChange, directory_path, isSelected, isHighlighted }) => {
  const formatLength = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className={isSelected ? "playing" : "notPlaying"} onClick={() => onTrackChange(data.id, 'click')}>
      <div className={`track ${isHighlighted ? 'selectedTrack' : ''}`}>
          <button className="play">
            <FaPlay />
          </button>
          <span className="track-number">{data.track_number + "."}</span>
          <span className="track-artist">{data.artist}</span>
          <span className="track-title">{data.title}</span>
          <span className="track-length">{formatLength(data.length)}</span>
      </div>
    </div>
  );
};

export default Track;
