import React, { useEffect, useState } from "react";
import axios from 'axios';
import './Logo.css'

const Logo = ({ token }) => {

  const [embed, setEmbed] = useState(null);
  const [notification, setNotification] = useState(null);

  async function fetchEmbed() {
    const URL = `${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_REST_PATH}/embed`;
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data[0]?.url) {
        setEmbed(res.data[0]);
      } else {
        setEmbed(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchNotification() {
    const URL = `${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_REST_PATH}/notification`;
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data[0]?.message) {
        setNotification(res.data[0]);
      } else {
        setNotification(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // initial fetch
    fetchEmbed();
    fetchNotification();
  }, []);

  useEffect(() => {
    // Fetch embed every 10 seconds
    const intervalId = setInterval(() => {
      fetchNotification();
      fetchEmbed();
    }, 9999);

    return () => clearInterval(intervalId);
  }, [token]);

  return (
    <div className="logo-container">
      <div className="logo">
        {!embed && (<img src="./mietaa_logo.png" className="logo-image" alt="mietaa" />)}
        {embed && (<iframe className="youtube-video" src={`https://www.youtube.com/embed/${embed.url}?autoplay=1&mute=1`} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>)}
      </div>
      {notification && !embed && (<div className="notification">{notification.message}</div>)}
    </div>
  );
};

export default Logo;
