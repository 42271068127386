import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmojiRenderer from '../EmojiRenderer/EmojiRenderer';
import './Shoutbox.css';

const Shoutbox = ({ shoutbox_id, token, onNickChange, nick }) => {
  const [message, setMessage] = useState('');
  const [nickname, setNickname] = useState(nick ? nick : '');
  const [shouts, setShouts] = useState([]);

  async function fetchIp() {
    const URL = 'https://api.ipify.org?format=json';
    try {
      const res = await axios.get(URL);
      if(res.data.ip){
        return res.data.ip;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchShouts() {
    const URL = `${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_REST_PATH}/shout/${shoutbox_id}`;
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShouts(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // Initial fetch
    fetchShouts();

    // Fetch shouts every 5 seconds
    const intervalId = setInterval(() => {
      fetchShouts();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [token]);

  async function handleShout() {
    const ip = await fetchIp();
    if(ip){
      axios
      .post(
        `${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_REST_PATH}/shout/`,
        {
          ip,
          nickname,
          message,
          shoutbox_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setMessage('');
        fetchShouts();
        onNickChange(nickname);
        console.log('Shout sent successfully');
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error sending shout:', error);
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
    };

    const formattedTime = new Intl.DateTimeFormat('fi-FI', options).format(
      new Date(timestamp)
    );

    return formattedTime.replace('.', ':');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'Enter':
            handleShout();
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleShout]);

  return (
    <div className="shoutbox">
      <div className="inputs">
        <input
          id="shoutbox-nick"
          maxLength={20}
          className="nick"
          type="text"
          placeholder="nickname..."
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <input
          id="shoutbox-message"
          maxLength={255}
          className="message"
          type="text"
          placeholder="message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={handleShout}>POST</button>
      </div>
      <div className="shouts">
        {shouts.map((shout) => (
          <div
            key={shout.id}
            className="shout"
          >
            <span className="timestamp">
            {"[" + formatTimestamp(shout.timestamp) + "]"}
            </span>
            <span className="nick"> {shout.nickname}</span>
            <EmojiRenderer text= {": " + shout.message} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shoutbox;
