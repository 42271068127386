import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Release from '../../components/Release/Release';
import { useSwipeable } from 'react-swipeable';
import './Catalog.css';

const Catalog = ({ handleReleases, handlePageChange, token, onTrackChange, onReleaseChange, currentTrack, currentRelease }) => {
  const { releaseId } = useParams();
  const releaseIdAsNumber = parseInt(releaseId, 10);
  const [releases, setReleases] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [selectedRelease, setSelectedRelease] = useState(releaseIdAsNumber ? releaseIdAsNumber : null);
  const [searchTerm, setSearchTerm] = useState('');
  const [track, setTrack] = useState({});
  const [releaseOpen, setReleaseOpen] = useState(releaseIdAsNumber ? true : null);
  const [noResults, setNoResults] = useState(false);
  const [slideDir, setSlideDir] = useState('');
  const navigate = useNavigate();

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if(eventData.dir === 'Right'){
        if(selectedRelease){
          handleReleaseChange(selectedRelease, 'prev');
        } else {
          handleReleaseChange(filteredReleases[0].id, 'prev');
        }
      }
      if(eventData.dir === 'Left'){
        if(selectedRelease){
          handleReleaseChange(selectedRelease, 'next');
        } else {
          handleReleaseChange(filteredReleases[filteredReleases.length - 1].id, 'next');
        }
      }
    },...{},
  });

  useEffect(() => {
    handlePageChange("catalog");
    const fetchReleasesAndTracks = async () => {
      try {
        const releasesResponse = await fetch(`${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_REST_PATH}/release`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!releasesResponse.ok) {
          throw new Error('Failed to fetch releases');
        }

        const releasesData = await releasesResponse.json();
        const trackList = [];
        releasesData.forEach(releaseData => {
          releaseData.tracks.forEach(trackData => {
            trackList.push(trackData);
          })
        });
        setTracks(trackList);
        setReleases(releasesData);
        handleReleases(releasesData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchReleasesAndTracks();
  }, [token]);



  const handleReleaseChange = (id, direction) => {
    let releaseToSelect = id;

    if (direction === 'prev') {
      setReleaseOpen(true);
      setSlideDir('w3-animate-left');
      releaseToSelect = getPreviousReleaseId(id, filteredReleases);
    }
    if (direction === 'next') {
      setReleaseOpen(true);
      setSlideDir('w3-animate-right');
      releaseToSelect = getNextReleaseId(id, filteredReleases);
    }
    if (direction === 'enter' && !noResults) {
      setReleaseOpen(true);
      releaseToSelect = filteredReleases[0].id;
    }
    if (direction === 'open') {
      setReleaseOpen(true);
    }
    if (direction === 'close') {
      setSlideDir(null);
      setReleaseOpen(false);
    }

    setSelectedRelease(releaseToSelect);
    if(releaseToSelect != null){
      navigate(`/catalog/${releaseToSelect}`);
    } else {
      navigate(`/catalog`);
    }
    window.scrollTo(0, 0)
    onReleaseChange(releases.find(r => r.id === releaseToSelect));
  };

  const handleTrackChange = (id, direction) => {
    let trackToSelect = id;

    if (direction === 'prev') {
      trackToSelect = getPreviousTrackId(id);
      setSelectedTrack(trackToSelect);
    } else if (direction === 'next') {
      trackToSelect = getNextTrackId(id);
      setSelectedTrack(trackToSelect);
    }
    if(direction === 'click'){
      onTrackChange(getTrackById(id));
      setSelectedTrack(id);
    }
    if(direction === 'enter'){
      onTrackChange(getTrackById(id));
    }
    if(!direction){
      setSelectedTrack(id);
      onTrackChange(getTrackById(id));
    }
  };

  const getTracksForReleaseById = (id) => {
    const release = releases.find(r => r.id === id);
    const filteredTracks = tracks.filter((track) => track.album.includes(release?.album));
    return filteredTracks;
  };

  useEffect(() => {
    if(currentRelease){
      navigate(`/catalog/${currentRelease.id}`);
      setSelectedRelease(currentRelease.id);
      setReleaseOpen(true);
    }
    if(currentTrack){
      if(!selectedRelease){
        setSelectedTrack(currentTrack.id);
        setTrack(currentTrack);
      }
    }
  }, [currentRelease, currentTrack]);

  const getPreviousReleaseId = (currentId, filtered) => {
    const currentIndex = filtered.findIndex((release) => release.id === currentId);
    const totalReleases = filtered.length;
    const previousIndex = (currentIndex - 1 + totalReleases) % totalReleases;
    return filtered[previousIndex].id;
  };

  const getPreviousTrackId = (currentId) => {
    const albumTrackIds = getTracksForReleaseById(selectedRelease).map((track) => {
        return track.id;
    })
    const currentIndex = albumTrackIds.findIndex((id) => id === currentId);
    const totalTracks = albumTrackIds.length;
    const previousIndex = (currentIndex - 1 + totalTracks) % totalTracks;
    return albumTrackIds.includes(selectedTrack) ? albumTrackIds[previousIndex] : albumTrackIds[albumTrackIds.length -1];
  };

  const getNextReleaseId = (currentId, filtered) => {
    const currentIndex = filtered.findIndex((release) => release.id === currentId);
    const totalReleases = filtered.length;
    const nextIndex = (currentIndex + 1) % totalReleases;
    return filtered[nextIndex].id;
  };

  const getNextTrackId = (currentId) => {
    const albumTracks = getTracksForReleaseById(selectedRelease);
    const currentIndex = albumTracks.findIndex((track) => track.id === currentId);
    const nextIndex = (currentIndex + 1) % albumTracks.length;
    return albumTracks[nextIndex].id;
  };

  const filteredReleases = releases.filter((release) => {
    const releaseTracks = getTracksForReleaseById(release.id);
    const trackNames = releaseTracks.map((track) => {
      return track.title;
    })
    const releaseInfo = `${release.artist} ${release.album} ${trackNames.join(' ')}`.toLowerCase();
    return releaseInfo.includes(searchTerm.toLowerCase());
  });

  const getTrackById = (id) => {
    return tracks.find((track) => track.id === id);
  };

  useEffect(() => {
    setTrack(getTrackById(selectedTrack));
  }, [selectedTrack]);

  useEffect(() => {
    setNoResults(filteredReleases.length < 1);
  }, [setSearchTerm, filteredReleases]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'Escape':
          setReleaseOpen(false);
          handleReleaseChange(null, null);
          break;
        case 'ArrowLeft':
          if(selectedRelease){
            setSelectedTrack(getTracksForReleaseById(selectedRelease)[0].id);
            handleReleaseChange(selectedRelease, 'prev');
          } else {
            handleReleaseChange(filteredReleases[0].id, 'prev');
          }
          break;
        case 'ArrowRight':
          if(selectedRelease){
            setSelectedTrack(getTracksForReleaseById(selectedRelease)[0].id);
            handleReleaseChange(selectedRelease, 'next');
          } else {
            handleReleaseChange(filteredReleases[filteredReleases.length - 1].id, 'next');
          }
          break;
        case 'ArrowUp':
          if(selectedRelease){
            handleTrackChange(selectedTrack, 'prev');
          }
          break;
        case 'ArrowDown':
          if(selectedRelease){
            handleTrackChange(selectedTrack, 'next');
          }
          break;
        case 'Enter':
            if(!selectedRelease){
              handleReleaseChange(null, 'enter');
              break;
            }
            handleTrackChange(selectedTrack, 'enter');
            event.preventDefault();
            break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedRelease, selectedTrack, releases, filteredReleases]);

  const searchHandler = (value) => {
    setSearchTerm(value);
    setNoResults(filteredReleases.length === 0 ? true : false);
  }

  return (
    <div className="catalog" {...handlers}>
      { !releaseOpen && (
        <div className="search">
          <input
            name="search"
            className="search"
            type="text"
            placeholder="search releases..."
            value={searchTerm}
            onChange={(e) => searchHandler(e.target.value)}
          />
        </div>
        )
      }
      <div className="catalogOverlay">
        { filteredReleases.map((release) => (
          <Release
            key={release.id}
            data={release}
            tracks={release.tracks}
            token={token}
            selected={release.id === selectedRelease}
            onReleaseChange={handleReleaseChange}
            onTrackChange={handleTrackChange}
            currentTrack={currentTrack?.id}
            selectedTrack={selectedTrack}
            releaseOpen={releaseOpen}
            slideDir={slideDir}
          />
        ))}
      </div>
      {noResults ? <span className="noResults">No releases found for your query...</span> : <span></span>}
    </div>
  );
};

export default Catalog;
