import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Helmet } from "react-helmet";
import Catalog from "./pages/Catalog/Catalog";
import Navigation from "./components/Navigation/Navigation";
import MusicPlayer from "./components/MusicPlayer/MusicPlayer";
import TokenClient from "./utils/token";
import "./App.css";

function App() {
  const [token, setToken] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentRelease, setCurrentRelease] = useState(null);
  const [releases, setReleases] = useState([]);
  const [nick, setNick] = useState(null);
  const [page, setPage] = useState("chat");
  const [isRadioPlaying, setIsRadioPlaying] = useState(false);
  const [playlist, setPlaylist] = useState(null);
  const opengraphImage = `${process.env.REACT_APP_BASE_PATH}/mietaa_logo.png`;

  const setupToken = async () => {
    const tokenClient = new TokenClient(process.env.REACT_APP_BASE_PATH, setToken);
    const loginSuccessful = await tokenClient.getToken();

    if (loginSuccessful) {
      setToken(tokenClient.getTokenValue());
      tokenClient.startTokenRenewal(); // Start automatic token renewal
    }
  };

  useEffect(() => {
    setupToken();
  }, []);

  const handleTrackChange = useCallback((track) => {
    setIsRadioPlaying(false);
    setCurrentTrack(track);
    setPlaylist(releases.find(release => release.album === track.album));
  }, [releases]);

  const handleReleaseChange = useCallback((release) => {
    setCurrentRelease(release);
  }, [currentTrack]);

  const handleNickChange = useCallback((nick) => {
    setNick(nick);
  }, []);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const handleReleases = useCallback((releases) => {
    setReleases(releases);
  }, []);

  const radioOn = () => {
    setCurrentTrack(null);
    setIsRadioPlaying(true);
  };

  const radioOff = () => {
    setIsRadioPlaying(false);
  };

  return (
    <Router>
      <div className="App">
        {!currentRelease && (
          <Helmet key="app">
            <meta property="og:title" content="MIETAA" />
            <meta
              property="og:description"
              content="Explore and enjoy a diverse collection of sounds on MIETAA online audio platform"
            />
            <meta property="og:image" content={opengraphImage} />
          </Helmet>
        )}
        <Routes>
          {token && (
            <Route
              path="/"
              element={
                <Home
                  token={token}
                  onNickChange={handleNickChange}
                  nick={nick}
                />
              }
            />
          )}
          {token && (
            <Route
              path="/catalog"
              element={
                <Catalog
                  handleReleases={handleReleases}
                  handlePageChange={handlePageChange}
                  token={token}
                  onTrackChange={handleTrackChange}
                  onReleaseChange={handleReleaseChange}
                  currentTrack={currentTrack}
                  currentRelease={currentRelease}
                />
              }
            />
          )}
          {token && (
            <Route
              path="/catalog/:releaseId"
              element={
                <Catalog
                  handleReleases={handleReleases}
                  handlePageChange={handlePageChange}
                  token={token}
                  onTrackChange={handleTrackChange}
                  onReleaseChange={handleReleaseChange}
                  currentTrack={currentTrack}
                  currentRelease={currentRelease}
                />
              }
            />
          )}
        </Routes>
        <div className="bottom">
          <Navigation
            onPageChange={handlePageChange}
            page={page}
            radioOn={radioOn}
            radioOff={radioOff}
            isRadioPlaying={isRadioPlaying}
          />
          <MusicPlayer
            token={token}
            track={currentTrack}
            release={playlist}
            radioOn={radioOn}
            radioOff={radioOff}
            isRadioPlaying={isRadioPlaying}
            onTrackChange={handleTrackChange}
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
