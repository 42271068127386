import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaItunesNote, FaComment } from "react-icons/fa";
import { PiBroadcastFill } from "react-icons/pi";
import './Navigation.css';

const Navigation = ({onPageChange, page, radioOn, radioOff, isRadioPlaying}) => {
  const location = useLocation();
  let queryParams = "";
  if(location.pathname){
    const catalogLocationArr = location.pathname.split("/");
    if(catalogLocationArr[2] && catalogLocationArr[2] != ""){
      queryParams = catalogLocationArr[2];
    }
  }

  return (
    <div className="navigation">
    <Link to="/" >
      <button className={page === "chat" ? "active" : ""} onClick={ ()=> {
        if(page !== "chat"){
          onPageChange("chat")
        }
      }}>
        <FaComment />
        <span className="buttonText">chat</span>
      </button>
      </Link>
      <Link to={`/catalog/${queryParams}`}>
        <button className={page === "catalog" ? "active" : ""}  onClick={ ()=> {
          if(page !== "catalog"){
            onPageChange("catalog")
          }
        }}>
        <FaItunesNote />
        <span className="buttonText">music</span>
      </button>
      </Link>
      <button className={isRadioPlaying ? "playing" : ""}  onClick={ ()=> isRadioPlaying ? radioOff() : radioOn() }>
        <PiBroadcastFill />
        <span className="buttonText">radio</span>
      </button>
    </div>
  );
};

export default Navigation;
